import "core-js/modules/es.array.push.js";
import { defineComponent, toRefs, reactive, computed, watch, nextTick } from 'vue';
import Eldialog from "@/components/Eldialog/index.vue";
import TypeSelect from "@/components/TypeSelect/TypeSelect.vue";
import OrganizeSelect from "@/components/OrganizeSelect/index.vue";
import API from "@/api/bmsdata/";
import { useMessage } from "@/hooks/web/useMessage";
import { useDebounce } from '@/hooks/core/useDebounce';
import { ElMessageBox } from "element-plus";
import { formatDate } from '@/utils/formatTime';
import ApiCmd from '@/api/instruction';
export default defineComponent({
  name: "RuleFormDialog",
  components: {
    Eldialog,
    TypeSelect,
    OrganizeSelect
  },
  props: {
    Info: {
      type: Object,
      default: null
    },
    objectid: {
      type: Number,
      default: null
    },
    visible: {
      type: Boolean,
      default: false
    }
  },
  emits: ['update:visible', 'handleSuccess'],
  setup(props, {
    emit
  }) {
    const message = useMessage();
    const messageBox = useMessage();
    const intervalObj = {
      ms: 1,
      timerId: null
    };
    const refData = reactive({
      activeValue: "",
      searchValue: "",
      updateTime: 5,
      value: "",
      ruleForm: null,
      formData: null,
      mp2Data: {},
      mp2_903D: '',
      mp2_9003: '0',
      mSocImg: 3,
      title: '新增',
      maxVol: 0,
      mixVol: 0,
      marginV: 0,
      mpttS1: '0',
      mpttS2: '0',
      mpttS3: '0',
      mpttS2_A0: '0',
      mpttS2_A1: '0',
      mpttS2_A23: '00',
      mpttS3_A0: '0',
      mpttS3_A1: '0',
      mpttS3_A23: '00',
      mpttS2_Status: '',
      mpttS1_Status: '',
      mpttS3_Status: '',
      mpttS1_A03: '0000',
      mpttS1_A47: '0000',
      mpttS1_A15: '00',
      avtiveTabname: "BMS",
      batteryList: [[{
        pos: 1,
        value: 3.3
      }]],
      ruleList: [{
        value: 1,
        label: '人工巡检'
      }, {
        value: 2,
        label: '系统巡检'
      }, {
        value: 3,
        label: '其他'
      }],
      rules: {
        holdid: [{
          requidanger: true,
          message: '请输入所属单位'
        }],
        deviceType: [{
          requidanger: true,
          message: "请选择设备类型"
        }],
        mdtid2: [{
          requidanger: true,
          message: "请输入电池编号",
          type: 'string',
          trigger: 'blur'
        }, {
          min: 1,
          max: 20,
          message: '长度在 1 到 20 个字符',
          trigger: 'blur'
        }, {
          pattern: /^[\u4E00-\u9FA5a-zA-Z0-9_]*$/,
          message: '用户名称不能带有特殊符号'
        }],
        location: [{
          requidanger: true,
          message: "请输入安装位置",
          type: 'string',
          trigger: 'blur'
        }, {
          min: 1,
          max: 20,
          message: '长度在 1 到 20 个字符',
          trigger: 'blur'
        }, {
          pattern: /^[\u4E00-\u9FA5a-zA-Z0-9_]*$/,
          message: '安装位置不能带有特殊符号'
        }]
      },
      confirm() {
        refData.ruleForm.validate(async valid => {
          if (valid) {
            try {
              const url = refData.formData.id ? 'updateDevice' : 'addDevice';
              const {
                code,
                msg,
                data
              } = await API[url](refData.formData);
              if (code == 0 && !refData.formData.id) {
                ElMessageBox.confirm(`设备管理录入成功`, '提示', {
                  confirmButtonText: '是',
                  cancelButtonText: '否',
                  type: 'success'
                }).then(async () => {
                  emit('handleSuccess', data);
                }).catch(() => {
                  emit('handleSuccess', 0);
                });
              } else {
                emit('handleSuccess', 0);
                message[code == 0 ? 'success' : 'warning'](code == 0 ? '修改成功' : msg);
              }
            } catch (error) {
              message.warning(error.msg);
            }
          } else {
            console.log("error submit!!");
            return false;
          }
        });
        console.log('提交');
      },
      getCrc16modbus(dataHexString) {
        const dataBytes = [];
        for (let i = 0; i < dataHexString.length; i += 2) {
          dataBytes.push(parseInt(dataHexString.substr(i, 2), 16));
        }
        let crc = 0xFFFF;
        const polynomial = 0xA001; // This is the polynomial x^16 + x^15 + x^2 + 1 
        for (const byte of dataBytes) {
          crc ^= byte;
          for (let i = 0; i < 8; i++) {
            if (crc & 0x0001) {
              crc = (crc >> 1 ^ polynomial) & 0xFFFF;
            } else {
              crc >>= 1;
            }
          }
        }
        let hexcrc = crc.toString(16).toUpperCase().padStart(4, '0');
        return hexcrc.substr(2, 2) + hexcrc.substr(0, 2);
      },
      getBatteryClass(voltage) {
        var classname = voltage >= this.maxVol ? " batteryStatu1" : "batteryStatu4";
        if (voltage <= this.mixVol) {
          classname = " batteryStatu2";
        }
        return "battery-item " + classname;
      },
      async getData(id) {
        const {
          data,
          code,
          msg
        } = await API.getBmsDataByID({
          id
        });
        console.log("data" + JSON.stringify(data));
        if (code == 0) {
          refData.formData = data;
          refData.formData.objectid = id;
          refData.formData.recvtime = formatDate(refData.formData.recvtime);
          refData.activeValue = `org-${data.holdid}`;
          refData.searchValue = data.holdname;
          refData.batteryList = [];
          var batteryListTemp = [];
          batteryListTemp.push({
            pos: 1,
            value: refData.formData.c1 / 1000
          });
          batteryListTemp.push({
            pos: 2,
            value: refData.formData.c2 / 1000
          });
          batteryListTemp.push({
            pos: 3,
            value: refData.formData.c3 / 1000
          });
          batteryListTemp.push({
            pos: 4,
            value: refData.formData.c4 / 1000
          });
          batteryListTemp.push({
            pos: 5,
            value: refData.formData.c5 / 1000
          });
          batteryListTemp.push({
            pos: 6,
            value: refData.formData.c6 / 1000
          });
          batteryListTemp.push({
            pos: 7,
            value: refData.formData.c7 / 1000
          });
          batteryListTemp.push({
            pos: 8,
            value: refData.formData.c8 / 1000
          });
          batteryListTemp.push({
            pos: 9,
            value: refData.formData.c9 / 1000
          });
          batteryListTemp.push({
            pos: 10,
            value: refData.formData.c10 / 1000
          });
          batteryListTemp.push({
            pos: 11,
            value: refData.formData.c11 / 1000
          });
          batteryListTemp.push({
            pos: 12,
            value: refData.formData.c12 / 1000
          });
          batteryListTemp.push({
            pos: 13,
            value: refData.formData.c13 / 1000
          });
          batteryListTemp.push({
            pos: 14,
            value: refData.formData.c14 / 1000
          });
          batteryListTemp.push({
            pos: 15,
            value: refData.formData.c15 / 1000
          });
          batteryListTemp.push({
            pos: 16,
            value: refData.formData.c16 / 1000
          });
          var maxVol = 0;
          var mixVol = 0;
          batteryListTemp.forEach((items, index) => {
            var vol = items.value;
            if (index == 0) {
              maxVol = vol;
              mixVol = vol;
            }
            if (vol > maxVol) {
              maxVol = vol;
            } else if (vol < mixVol) {
              mixVol = vol;
            }
          });
          this.maxVol = maxVol;
          this.mixVol = mixVol;
          this.marginV = (maxVol - mixVol).toFixed(3);
          this.batteryList = this.group(batteryListTemp, 4);
          refData.mpttS1 = data.mp2S1.toString(2).padStart(16, '0');
          refData.mpttS2 = data.mp2S2.toString(2).padStart(16, '0');
          refData.mpttS3 = data.mp2S3.toString(2).padStart(16, '0');
          var lenS1 = refData.mpttS1.length;
          var lenS2 = refData.mpttS2.length;
          var lenS3 = refData.mpttS3.length;
          refData.mpttS1_A03 = lenS1 >= 4 ? refData.mpttS1.substring(lenS1 - 4, lenS1) : '0000';
          refData.mpttS1_A47 = lenS1 >= 8 ? refData.mpttS1.substring(lenS1 - 8, lenS1 - 4) : '0000';
          if (refData.mpttS1.substring(lenS1 - 4, lenS1) == "0000" || lenS1 < 4) {
            refData.mpttS1_Status = "正常,";
          }
          if (refData.mpttS1.substring(lenS1 - 4, lenS1) == "0001") {
            refData.mpttS1_Status = "超压,";
          }
          if (refData.mpttS1.substring(lenS1 - 4, lenS1) == "0010") {
            refData.mpttS1_Status = "欠压,";
          }
          if (refData.mpttS1.substring(lenS1 - 4, lenS1) == "0011") {
            refData.mpttS1_Status = "过放,";
          }
          if (refData.mpttS1.substring(lenS1 - 4, lenS1) == "0100") {
            refData.mpttS1_Status = "错误,";
          }
          if (refData.mpttS1.substring(lenS1 - 8, lenS1 - 4) == "0000") {
            refData.mpttS1_Status += "温度:正常,";
          }
          if (refData.mpttS1.substring(lenS1 - 8, lenS1 - 4) == "0001") {
            refData.mpttS1_Status += "温度:过高温,";
          }
          if (refData.mpttS1.substring(lenS1 - 8, lenS1 - 4) == "0010") {
            refData.mpttS1_Status += "温度:过低温,";
          }
          if (refData.mpttS1.substring(lenS1 - 9, lenS1 - 8) == "1") {
            refData.mpttS1_Status += "蓄电池内阻异常,";
          }
          if (refData.mpttS1.substring(lenS1 - 16, lenS1 - 15) == "1") {
            refData.mpttS1_Status += "额定电压识别错误,";
          }
          refData.mpttS2_A0 = lenS2 >= 1 ? refData.mpttS2.substring(lenS2 - 1, lenS2) : '0';
          refData.mpttS2_A1 = lenS2 >= 2 ? refData.mpttS2.substring(lenS2 - 2, lenS2 - 1) : '0';
          refData.mpttS2_A23 = lenS2 >= 3 ? refData.mpttS2.substring(lenS2 - 4, lenS2 - 2) : '00';
          if (refData.mpttS2.substring(lenS2 - 1, lenS2) == "1") {
            refData.mpttS2_Status = "运行,";
          } else {
            refData.mpttS2_Status = "待机,";
          }
          if (refData.mpttS2.substring(lenS2 - 2, lenS2 - 1) == "1") {
            refData.mpttS2_Status += "故障,";
          } else {
            refData.mpttS2_Status += "正常,";
          }
          if (refData.mpttS2.substring(lenS2 - 4, lenS2 - 2) == "00") {
            refData.mpttS2_Status += "充电状态:未充电,";
          }
          if (refData.mpttS2.substring(lenS2 - 4, lenS2 - 2) == "01") {
            refData.mpttS2_Status += "充电状态:浮充,";
          }
          if (refData.mpttS2.substring(lenS2 - 4, lenS2 - 2) == "10") {
            refData.mpttS2_Status += "充电状态:提升,";
          }
          if (refData.mpttS2.substring(lenS2 - 4, lenS2 - 2) == "11") {
            refData.mpttS2_Status += "充电状态: 均衡,";
          }
          if (refData.mpttS2.substring(lenS2 - 6, lenS2 - 5) == "1") {
            refData.mpttS2_Status += "PV输入短路,";
          }
          if (refData.mpttS2.substring(lenS2 - 7, lenS2 - 6) == "1") {
            refData.mpttS2_Status += "三路不均衡,";
          }
          if (refData.mpttS2.substring(lenS2 - 8, lenS2 - 4) == "1") {
            refData.mpttS2_Status += "负载MOS管短路,";
          }
          if (refData.mpttS2.substring(lenS2 - 9, lenS2 - 8) == "1") {
            refData.mpttS2_Status += "负载短路,";
          }
          if (refData.mpttS2.substring(lenS2 - 10, lenS2 - 9) == "1") {
            refData.mpttS2_Status += "充电带负载型设备负载过流,";
          }
          if (refData.mpttS2.substring(lenS2 - 11, lenS2 - 10) == "1") {
            refData.mpttS2_Status += "输入过流,";
          }
          if (refData.mpttS2.substring(lenS2 - 12, lenS2 - 11) == "1") {
            refData.mpttS2_Status += "防反 MOS 管短路,";
          }
          if (refData.mpttS2.substring(lenS2 - 13, lenS2 - 12) == "1") {
            refData.mpttS2_Status += "充电或防反 MOS 管断路,";
          }
          if (refData.mpttS2.substring(lenS2 - 14, lenS2 - 13) == "1") {
            refData.mpttS2_Status += "充电 MOS 管短路,";
          }
          if (refData.mpttS2.substring(lenS2 - 16, lenS2 - 14) == "00") {
            refData.mpttS2_Status += "输入电源:正常,";
          }
          if (refData.mpttS2.substring(lenS2 - 16, lenS2 - 14) == "01") {
            refData.mpttS2_Status += "输入电源:未接入,";
          }
          if (refData.mpttS2.substring(lenS2 - 16, lenS2 - 14) == "10") {
            refData.mpttS2_Status += "输入电源:电压过高,";
          }
          if (refData.mpttS2.substring(lenS2 - 16, lenS2 - 14) == "11") {
            refData.mpttS2_Status += "输入电源:错误,";
          }
          refData.mpttS3_A0 = lenS3 >= 1 ? refData.mpttS3.substring(lenS3 - 1, lenS3) : '0';
          refData.mpttS3_A1 = lenS3 >= 2 ? refData.mpttS3.substring(lenS3 - 2, lenS3 - 1) : '0';
          refData.mpttS3_A23 = lenS3 >= 3 ? refData.mpttS3.substring(lenS3 - 4, lenS3 - 2) : '00';
          if (refData.mpttS3.substring(lenS3 - 1, lenS3) == "1") {
            refData.mpttS3_Status = "运行,";
          } else {
            refData.mpttS3_Status = "待机,";
          }
          if (refData.mpttS3.substring(lenS3 - 2, lenS3 - 1) == "1") {
            refData.mpttS3_Status += "故障,";
          } else {
            refData.mpttS3_Status += "正常,";
          }
          if (refData.mpttS3.substring(lenS3 - 5, lenS3 - 4) == "1") {
            refData.mpttS3_Status += "输出超压,";
          }
          if (refData.mpttS3.substring(lenS3 - 6, lenS3 - 5) == "1") {
            refData.mpttS3_Status += "升压超压,";
          }
          if (refData.mpttS3.substring(lenS3 - 7, lenS3 - 6) == "1") {
            refData.mpttS3_Status += "高压侧短路,";
          }
          if (refData.mpttS3.substring(lenS3 - 8, lenS3 - 7) == "1") {
            refData.mpttS3_Status += "输入端过流,";
          }
          if (refData.mpttS3.substring(lenS3 - 9, lenS3 - 8) == "1") {
            refData.mpttS3_Status += "输出电压异常,";
          }
          if (refData.mpttS3.substring(lenS3 - 10, lenS3 - 9) == "1") {
            refData.mpttS3_Status += "无法停止放电,";
          }
          if (refData.mpttS3.substring(lenS3 - 11, lenS3 - 10) == "1") {
            refData.mpttS3_Status += "无法放电,";
          }
          if (refData.mpttS3.substring(lenS3 - 12, lenS3 - 11) == "1") {
            refData.mpttS3_Status += "短路,";
          }
          if (refData.mpttS3.substring(lenS3 - 14, lenS3 - 12) == "00") {
            refData.mpttS3_Status += "输出功率:轻载,";
          }
          if (refData.mpttS3.substring(lenS3 - 14, lenS3 - 12) == "01") {
            refData.mpttS3_Status += "输出功率:中度,";
          }
          if (refData.mpttS3.substring(lenS3 - 14, lenS3 - 12) == "10") {
            refData.mpttS3_Status += "输出功率:额定,";
          }
          if (refData.mpttS3.substring(lenS3 - 14, lenS3 - 12) == "11") {
            refData.mpttS3_Status += "输出功率:过载,";
          }
          if (refData.mpttS3.substring(lenS3 - 16, lenS3 - 14) == "00") {
            refData.mpttS3_Status += "输入电源:正常,";
          }
          if (refData.mpttS3.substring(lenS3 - 16, lenS3 - 14) == "01") {
            refData.mpttS3_Status += "输入电源:电压过低,";
          }
          if (refData.mpttS3.substring(lenS3 - 16, lenS3 - 14) == "10") {
            refData.mpttS3_Status += "输入电源:电压过高,";
          }
          if (refData.mpttS3.substring(lenS3 - 16, lenS3 - 14) == "11") {
            refData.mpttS3_Status += "输入电源:未接入,";
          }
          if (refData.formData.mp2Data != null) {
            refData.mp2Data = JSON.parse(refData.formData.mp2Data);
            if (refData.mp2Data["903D"]) {
              refData.mp2_903D = refData.mp2Data["903D"];
            }
            if (refData.mp2Data["9003"]) {
              refData.mp2_9003 = refData.mp2Data["9003"];
            }
          }
        } else {
          message.warning(msg);
        }
      },
      //处理数据
      group(array, subGroupLength) {
        let index = 0;
        let newArray = [];
        while (index < array.length) {
          newArray.push(array.slice(index, index += subGroupLength));
        }
        return newArray;
      },
      hex_to_ascii(str1) {
        var hex = str1.toString();
        var str = "";
        for (var n = 0; n < hex.length; n += 2) {
          str += String.fromCharCode(parseInt(hex.substr(n, 2), 16));
        }
        return str;
      },
      onOpenParamSend(cmdname, cmdkey, funcode) {
        //9000-900E 
        ElMessageBox.prompt('请输入' + cmdname, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消'
        }).then(({
          value
        }) => {
          var content = '';
          var intValue = parseInt(value);
          content = "01" + "10" + "9000" + "000F" + "1E"; //
          let cmdvalue = 0x9000;
          for (let i = 0; i < 16; i++) {
            if (cmdvalue.toString(16).toUpperCase() == cmdkey) {
              content += "" + intValue.toString(16).padStart(4, '0');
            } else {
              if (refData.mp2Data[cmdvalue.toString(16).toUpperCase()] != null) {
                content += "" + refData.mp2Data[cmdvalue.toString(16).toUpperCase()].toString(16).padStart(4, '0');
              }
            }
            // console.log(cmdvalue.toString(16) + "," + refData.mp2Data[cmdvalue.toString(16).toUpperCase()].toString(16)) 
            cmdvalue++;
          }
          ;
          // + intValue.toString(16).padStart(4,'0');
          content = content + this.getCrc16modbus(content);
          this.onSendTransData(cmdkey, content);
        }).catch(() => {});
      },
      onOpenParamSendSingle(cmdname, cmdkey, funcode) {
        ElMessageBox.prompt('请输入' + cmdname, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消'
        }).then(({
          value
        }) => {
          var content = '';
          var intValue = parseInt(value);
          content = "01" + funcode + cmdkey + "02" + intValue.toString(16).padStart(4, '0');
          content = content + this.getCrc16modbus(content);
          this.onSendTransData(cmdkey, content);
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '取消输入'
          });
        });
      },
      onSendQueryTag(cmdname, cmdkey, funcode, value) {
        var content = '';
        switch (cmdkey) {
          case 'mpFl':
            {
              content = this.getMPTTParam(cmdkey, value);
              break;
            }
          case 'mpZ1':
            {
              content = this.getMPTTParam(cmdkey, value);
              break;
            }
          case '903D':
            {
              //查询控制方式
              content = "0103903D0001";
              content = content + this.getCrc16modbus(content);
              break;
            }
          case '300E':
            {
              content = "0104300E0001";
              content = content + this.getCrc16modbus(content);
              break;
            }
          case '3005':
            {
              content = "010430050001";
              content = content + this.getCrc16modbus(content);
              break;
            }
          case '311D':
            {
              content = "0104311D0001";
              content = content + this.getCrc16modbus(content);
              break;
            }
          //soc
          case '311A':
            {
              content = "0104311A0001";
              content = content + this.getCrc16modbus(content);
              break;
            }
          default:
            {
              content = "01" + funcode + cmdkey + "0001";
              content = content + this.getCrc16modbus(content);
              break;
            }
        }
        ElMessageBox.confirm('是否发送查询[' + cmdname + ']指令', '提示', {
          confirmButtonText: '是',
          cancelButtonText: '否',
          type: 'success'
        }).then(async () => {
          this.onSendTransData(cmdkey, content);
        }).catch(() => {});
      },
      onSendSettParamTag(cmdname, cmdkey, funcode, value) {
        var content = '';
        switch (cmdkey) {
          case '0002':
            {
              //负载开关
              if (value == "0001") {
                content = "01050002FF00";
              } else {
                content = "010500020000";
              }
              console.log(content);
              content = content + this.getCrc16modbus(content);
              break;
            }
          default:
            {
              content = "01" + funcode + cmdkey + "000102" + value;
              content = content + this.getCrc16modbus(content);
              break;
            }
        }
        ElMessageBox.confirm('是否设置[' + cmdname + ']', '提示', {
          confirmButtonText: '是',
          cancelButtonText: '否',
          type: 'success'
        }).then(async () => {
          this.onSendTransData(cmdkey, content);
        }).catch(() => {});
      },
      getMPTTParam(paramName, paramValue) {
        var command = '{"GPS":';
        if (paramName == "mpZ1") {
          command = command + '"Z1":' + paramValue + ",";
        }
        if (paramName == "mpZ2") {
          command += '"Z2":' + paramValue + ",";
        }
        if (paramName == "mpZ3") {
          command += '"Z3":' + paramValue + ",";
        }
        if (paramName == "mpZ4") {
          command += '"Z4":' + paramValue + ",";
        }
        if (paramName == "mpFl") {
          command += '"FL":' + paramValue + ",";
        }
        command += "}";
        return command;
      },
      init() {
        refData.formData = {
          id: null,
          objectid: null,
          deviceType: 1,
          mdtid: null,
          mdtid3: null,
          mdtid2: null,
          holdid: null,
          holdname: ''
        };
      }
    });
    // 初始化表单
    refData.init();
    const show = computed({
      get: () => props.visible,
      set: val => {
        emit("update:visible", val);
      }
    });
    function startTiming() {
      const updateTime = 10;
      refData.updateTime = updateTime;
      console.log("starttiming");
      intervalObj.timerId = setInterval(() => {
        if (!intervalObj.timerId) return;
        if (show) {
          refData.updateTime--;
          if (refData.updateTime <= 0) {
            if (props.objectid != null) {
              refData.formData.objectid = props.objectid;
              refData.getData(props.objectid);
            } else {
              refData.formData.objectid = props.Info.id;
              refData.getData(props.Info.id);
            }
            refData.updateTime = 10;
          }
        }
      }, 1000);
    }
    //发送指令
    async function onSendTransData(cmdkey, content) {
      try {
        const objectid = refData.formData.objectid; //props.objectid as any;
        const param = {
          objectid: objectid,
          cmdtype: 14,
          cmddesc: content,
          cmdkey: cmdkey,
          cmdcontent: JSON.stringify({
            param_type: 0x82,
            param_content: content
          })
        };
        const result = await ApiCmd.sendMsg(param);
        console.log(result);
        message[result.code == 0 ? 'success' : 'error'](`指令下发${result.msg}`);
      } catch ({
        msg
      }) {
        message.warning(msg);
      }
    }
    ;
    watch(() => props.visible, val => {
      if (val) {
        refData.init();
        nextTick(() => {
          console.log("mptt-props.objectid:" + props.objectid);
          if (props.objectid != null) {
            refData.title = '查看状态';
            refData.getData(props.objectid);
          } else {
            refData.searchValue = props.Info.searchValue;
            refData.activeValue = props.Info.activeValue;
            if (props.Info.id !== null) {
              refData.title = '查看状态';
              refData.getData(props.Info.id);
            } else {
              refData.title = '新增设备';
              refData.formData.schoolId = props.Info.schoolId;
              setTimeout(() => {
                refData.ruleForm.clearValidate();
              }, 100);
            }
          }
          startTiming();
        });
      } else {
        clearInterval(intervalObj.timerId);
        intervalObj.timerId = null;
      }
    }, {
      immediate: true
    });
    // 选择单位回调
    const handleDoubleClick = data => {
      refData.formData.holdid = data.id;
    };
    const handleTabClick = (tab, event) => {
      console.log(tab, event);
    };
    const [onConfirm] = useDebounce(refData.confirm, 300);
    return {
      ...toRefs(refData),
      show,
      onSendTransData,
      handleDoubleClick,
      handleTabClick,
      onConfirm
    };
  }
});